import React from 'react'

export const BUTTON_STYLES =
  ' inline-flex justify-center items-center font-sansSerif transition-all antialiased disabled:cursor-not-allowed whitespace-nowrap hover:opacity-80'
export const BUTTON_PRIMARY_STYLE =
  ' text-white bg-brand-gold disabled:bg-gray-bg-2'

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  custom?: boolean
  dataCy?: string
  black?: boolean
  danger?: boolean
  muted?: boolean
  secondary?: boolean
  success?: boolean
  disabled?: boolean
  mobileDisabled?: boolean
  size?:
    | 'small'
    | 'inputForm'
    | 'inputFilters'
    | 'inputNormal'
    | 'normal'
    | 'large'
  as?: 'button' | 'a' | 'div'
  rounded?: boolean
  /** For the case when `as` is `a` (anchor) */
  href?: string
  download?: string
}

const Button: React.FC<Props> = ({
  children,
  className,
  custom,
  black,
  danger,
  dataCy,
  muted,
  secondary,
  success,
  disabled,
  mobileDisabled,
  size,
  as = 'button',
  rounded,
  ...other
}) => {
  const primary =
    !black && !danger && !muted && !secondary && !success && !custom
  const sizeVal = size || 'normal'
  const Component = as as any

  return (
    <Component
      data-cy={dataCy}
      className={
        `${BUTTON_STYLES}` +
        (primary ? BUTTON_PRIMARY_STYLE : '') +
        (sizeVal === 'small'
          ? ' h-[23px] px-[10px] text-p4 md:text-p4md'
          : '') +
        (sizeVal === 'inputForm'
          ? ' h-inputForm px-[8px] text-p2 md:text-p2md'
          : '') +
        (sizeVal === 'inputFilters'
          ? ' h-inputFilters px-[8px] text-p3 md:text-p3md'
          : '') +
        (sizeVal === 'inputNormal'
          ? ' h-inputNormal px-[8px] text-p3 md:text-p3md'
          : '') +
        (sizeVal === 'normal'
          ? ' h-[38px] px-[23px] text-p2 md:text-p2md'
          : '') +
        (sizeVal === 'large'
          ? ' h-[53px] px-[30px] text-h3 md:text-h3md'
          : '') +
        (black && !disabled ? ' bg-black text-white' : '') +
        (danger && !disabled
          ? ' bg-danger text-white hover:bg-danger-hover'
          : '') +
        (muted && !disabled
          ? ' bg-gray-bg-2 text-white hover:bg-gray-bg-2-hover'
          : '') +
        (secondary && !disabled
          ? ' border border-brand-gold bg-white hover:bg-brand-gold hover:text-white'
          : '') +
        (success && !disabled
          ? ' bg-success text-white hover:opacity-80 '
          : '') +
        (disabled
          ? ' cursor-not-allowed  bg-gray-bg-2 text-white disabled:bg-gray-bg-2 disabled:text-white'
          : '') +
        (mobileDisabled
          ? ' max-md:pointer-events-none max-md:cursor-not-allowed max-md:bg-gray-bg-2 max-md:text-white'
          : '') +
        (rounded ? ' rounded' : '') +
        (className ? ` ${className}` : '')
      }
      {...other}
      disabled={disabled}
    >
      {children}
    </Component>
  )
}

export default Button
